import nophoto from "../assets/images/nophoto.jpg";
import coffee from "../assets/images/IMG_1222.jpg";
import scramble from "../assets/images/IMG_1452.jpg";
import cr_los from "../assets/images/IMG_1135.jpg";
import cr_vet from "../assets/images/IMG_1154.jpg";
import cr_kur from "../assets/images/IMG_1151.jpg";
import kes from "../assets/images/IMG_1431.jpg";
import cherp from "../assets/images/IMG_1208.jpg";
import praga from "../assets/images/IMG_1295.jpg";
import zap from "../assets/images/IMG_1197.jpg";
import viena from "../assets/images/IMG_1165.jpg";
import braunie from "../assets/images/IMG_1181.jpg";
import potatoe from "../assets/images/IMG_1318.jpg";
import canele from "../assets/images/IMG_1114.jpg";
import yogt from "../assets/images/IMG_1309-2.jpg";
import danish_gol from "../assets/images/IMG_1066.jpg";
import bul_kor from "../assets/images/IMG_1042.jpg";
import ulit from "../assets/images/IMG_1029.jpg";
import cr_clas from "../assets/images//IMG_1086.jpg";
import cr_mind from "../assets/images/IMG_1098.jpg";
import cr_choc from "../assets/images/IMG_1101.jpg";
import tart_ya from "../assets/images/tart_yag.jpg";
import tart_kar from "../assets/images/tart_kar.jpg"
import tart_vish from "../assets/images/tart_vish.jpg"
import chia_mang from "../assets/images/chia_mang.jpg"

/*const drinkData = [
  {
    title: "Американо",
    description: "Свежесваренный ароматный черный кофе",
    price: 300,
    photo: coffee,
  },
];*/

export const foodData = [
  {
    title: "Скрембл с авокадо и сыром",
    description:
      "Воздушный скрембл с кремовым авокадо и расплавленным сыром. Лёгкий и питательный завтрак",
    price: 550,
    photo: scramble,
  },
  {
    title: "Круассан лосось",
    description:
      "Хрустящий круассан с нежным слабосоленым лососем и сливочным сыром. Идеальный перекус для гурманов",
    price: 430,
    photo: cr_los,
  },
  {
    title: "Круассан ветчина",
    description:
      "Хрустящий круассан с премиальной ветчиной из индейки и изысканным сыром Эмменталь. Для тех, кто ценит высокое качество и превосходный вкус",
    price: 350,
    photo: cr_vet,
  },
  {
    title: "Круассан курица",
    description:
      "Нежный круассан с сочной курицей и медово-горчичным соусом. Сбалансированный вкус с легкой сладостью и горчичной ноткой",
    price: 350,
    photo: cr_kur,
  },
  {
    title: "Кесадилья на гриле",
    description:
      "Хрустящая кесадилья с тающим сыром и пикантной начинкой. Идеальный выбор для любителей мексиканской кухни",
    price: 470,
    photo: kes,
  },
  {
    title: "Сырники (сметана / джем)",
    description:
      "Нежные сырники с золотистой корочкой. Подаются с выбором сметаны или ароматного джема для идеального завтрака",
    price: 350,
    photo: nophoto,
  },
  {
    title: "Цезарь с курицей",
    description:
      "Классический салат с сочной курицей, хрустящими гренками и фирменным соусом Цезарь. Сытно и свежо",
    price: 400,
    photo: nophoto,
  },
  {
    title: "Киноа-боул с лососем",
    description:
      "Полезный боул с киноа, нежным лососем и свежими овощами. Отличный выбор для лёгкого и питательного обеда",
    price: 550,
    photo: nophoto,
  },
];

const dessertData = [
  {
    title: "Черничный пирог",
    description:
      "Нежный пирог с сочной черникой и хрустящей корочкой. Идеальный баланс сладости и свежести",
    price: 350,
    photo: cherp,
  },
  {
    title: "Прага",
    description:
      "Шоколадный торт с насыщенным кремом и нежным бисквитом. Классический десерт для истинных сладкоежек",
    price: 430,
    photo: praga,
  },
  {
    title: "Запеканка с курагой",
    description:
      "Нежная творожная запеканка с кусочками сладкой кураги. Лёгкий и полезный десерт",
    price: 350,
    photo: zap,
  },
  {
    title: "Венский",
    description:
      "Ароматный бисквит с легкой пропиткой и нежным кремом. Изысканность в каждом кусочке",
    price: 380,
    photo: viena,
  },
  {
    title: "Брауни",
    description:
      "Плотный шоколадный брауни с насыщенным вкусом какао. Для тех, кто ценит истинный шоколад",
    price: 300,
    photo: braunie,
  },
  {
    title: "Картошка",
    description:
      "Классический десерт с богатым шоколадным вкусом и мягкой текстурой. Вкус детства",
    price: 220,
    photo: potatoe,
  },
  {
    title: "Канеле",
    description:
      "Французское лакомство с карамельной корочкой и нежной сердцевиной. Идеальное сочетание вкусов и текстур",
    price: 200,
    photo: canele,
  },
  {
    title: "Йогуртовый торт",
    description:
      "Легкий и освежающий торт на основе йогурта с фруктовыми нотками. Отличный выбор для тех, кто любит легкие десерты",
    price: 400,
    photo: yogt,
  },
  {
    title: "Трубочка со сгущенкой",
    description:
      "Хрустящая трубочка, наполненная сладкой сгущенкой. Настоящее удовольствие в каждом укусе",
    price: 210,
    photo: coffee,
  },
  {
    title: "Даниш с голубикой",
    description:
      "Воздушная слоенка с сочной голубикой и легким кремом. Идеально к утреннему кофе",
    price: 420,
    photo: danish_gol,
  },
  {
    title: "Булочка с корицей",
    description:
      "Мягкая булочка с ароматной корицей. Вкус тепла и уюта в каждом кусочке",
    price: 250,
    photo: bul_kor,
  },
  {
    title: "Улитка с изюмом",
    description:
      "Воздушная сдоба с изюмом и сладкой начинкой. Настоящее лакомство",
    price: 250,
    photo: ulit,
  },
  {
    title: "Круассан классика",
    description:
      "Хрустящий круассан с золотистой корочкой и нежной мякотью. Классика, которая всегда уместна",
    price: 230,
    photo: cr_clas,
  },
  {
    title: "Круассан миндаль",
    description:
      "Круассан с ароматом миндаля и сладкой начинкой. Французская роскошь на вашем столе",
    price: 300,
    photo: cr_mind,
  },
  {
    title: "Круассан шоколад",
    description:
      "Шоколадный круассан с богатой начинкой. Идеальный выбор для шоколадных гурманов",
    price: 300,
    photo: cr_choc,
  },
  {
    title: "Ягодная тарталетка",
    description:
      "Хрустящая песочная тарталетка с нежным заварным кремом и отборными свежими ягодами голубики и малины",
    price: 500,
    photo: tart_ya,
  },
  {
    title: "Тарталетка солёная карамель",
    description:
      "Хрустящая песочная тарталетка с арахисом, сырным кремом и тягучей соленой карамелью",
    price: 400,
    photo: tart_kar,
  },
  {
    title: "Тарталетка Вишня - Фисташка",
    description:
      "Тарталетка с фисташковым франжипаном, вишневым конфитюром и взбитым фисташковым ганашем",
    price: 460,
    photo: tart_vish,
  },
  {
    title: "Чиа Пудинг",
    description:
      "Пудинг на основе семян чиа и кокосового молока, кусочки ананаса и пюре манго-маракуйя. Десерт подходит для вегетарианцев",
    price: 450,
    photo: chia_mang,
  },
  {
    title: "Тирамису",
    description:
      "Классический итальянский десерт на основе нежного крема из сыра Маскарпоне и пропитанного в кофейном сиропе печенья Савоярди",
    price: 450,
    photo: nophoto,
  },
  {
    title: "Кекс морковный/столичный/творожный",
    description:
      "Домашний кекс с насыщенным вкусом. Выберите морковный, столичный или творожный — каждый по-своему уникален",
    price: 250,
    photo: nophoto,
  },
];

export const menuData = [
  /*{
    group: "drinks",
    name: "Напитки",
    items: drinkData,
  },*/
  {
    group: "food",
    name: "Сытно",
    items: foodData,
  },
  {
    group: "dessert",
    name: "Сладко",
    items: dessertData,
  },
];
