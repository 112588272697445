import { MenuListProps } from "../types/item.type";
import "./MenuLinks.css";

export const MenuLinks = ({ items, activeItem }: MenuListProps) => {
  return (
    <nav className="menu-links">
      {items &&
        items.map((item) => (
          <a
            key={item.group}
            href={`#${item.group}-start`}
            className={
              activeItem === item.group
                ? "menu-links-active"
                : "menu-links-item"
            }
          >
            {item.name}
          </a>
        ))}
    </nav>
  );
};
